import log from "loglevel";
import { contactBackend } from "./apiHelpers";
import { WebchatFeatureConfigDoc } from "./store/features/webchatFeatureConfigSlice";

export const configDataHandler = {
  async getConfigData(office?: string): Promise<WebchatFeatureConfigDoc> {
    try {
      const data = await contactBackend<WebchatFeatureConfigDoc>(
        `${process.env.REACT_APP_SERVER_URL}`,
        `/webchat-configuration${
          office ? "-" + office.toLocaleLowerCase() : ""
        }.json`,
        "GET"
      );
      log.debug("configDataHandler: getting web chat config sync doc");
      return data;
    } catch (e) {
      log.log(
        "configDataHandler: Error to handle API to get webChat config doc"
      );
      throw Error("No results from server");
    }
  },
};
