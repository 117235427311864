import { useSelector } from "react-redux";
import { AppState } from "../definitions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "../features/themeSlice";
import { StoreOption } from "./storeSlice";

export type WebchatFeatureConfigDoc = {
  theme: Theme;
  flexChatAddress: string;
  storeOptions: StoreOption[];
} & WebchatFeatureConfigState;

export type WebchatFeatureConfigState = {
  LogoUrl?: string;
  AlertUrl?: string;
  WhatsApp?: {
    isEnable: boolean;
    QRUrl: string;
    DeepLinkUrl: string;
  };
  SMS?: {
    isEnable: boolean;
    phoneNumber: string;
  };
  Call?: {
    isEnable: boolean;
    phoneNumber: string;
  };
};

const initialState: WebchatFeatureConfigState = {};

export const webchatFeatureConfigSlice = createSlice({
  name: "webchat-feature-config-slice",
  initialState,
  reducers: {
    applyWebchatFeatureConfig: (
      state,
      action: PayloadAction<WebchatFeatureConfigState>
    ) => {
      state.Call = action.payload.Call;
      state.LogoUrl = action.payload.LogoUrl;
      state.SMS = action.payload.SMS;
      state.WhatsApp = action.payload.WhatsApp;
      state.AlertUrl = action.payload.AlertUrl;
    },
  },
});

export const { applyWebchatFeatureConfig } = webchatFeatureConfigSlice.actions;
export const webchatFeatureConfigReducer = webchatFeatureConfigSlice.reducer;

export const useSelectWebChatConfig = () =>
  useSelector((state: AppState) => state.webchatFeatureConfiguration);
