import { configureStore } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { chatReducer, ChatState } from "./features/chatSlice";
import { configReducer, ConfigState } from "./features/configSlice";
import {
  notificationReducer,
  NotificationState,
} from "./features/notificationSlice";
import { sessionReducer, SessionState } from "./features/sessionSlice";
import { ThemeState, themeReducer } from "./features/themeSlice";
import {
  WebchatFeatureConfigState,
  webchatFeatureConfigReducer,
} from "./features/webchatFeatureConfigSlice";
import { storeReducer, StoreState } from "./features/storeSlice";

type WebchatStore = {
  chat: ChatState;
  config: ConfigState;
  notifications: NotificationState;
  session: SessionState;
  webchatFeatureConfiguration: WebchatFeatureConfigState;
  theme: ThemeState;
  store: StoreState;
};

let store: ToolkitStore<WebchatStore>;

export const getConfiguredStore = (): ToolkitStore<WebchatStore> => {
  if (!store) {
    store = configureStore({
      reducer: {
        chat: chatReducer,
        config: configReducer,
        notifications: notificationReducer,
        session: sessionReducer,
        webchatFeatureConfiguration: webchatFeatureConfigReducer,
        theme: themeReducer,
        store: storeReducer,
      },
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
    });
  }

  return store;
};

export const getDispatch = () => store.dispatch;
