import { Provider } from "react-redux";
import merge from "lodash.merge";
import { render } from "react-dom";
import { initLogger } from "./logger";
import { sessionDataHandler } from "./sessionDataHandler";
import { getDispatch, getConfiguredStore } from "./store/store";
import { ConfigState, loadConfig } from "./store/features/configSlice";
import { setThemes } from "./store/features/themeSlice";
import { applyWebchatFeatureConfig } from "./store/features/webchatFeatureConfigSlice";

import { configDataHandler } from "./configDataHandler";
import {
  setFlexChatAddress,
  setStoreOptions,
} from "./store/features/storeSlice";
import { setDefaultStoreOption } from "./store/features/sessionSlice";
import { getDefaultStoreOption } from "./utils/getDefaultStoreOption";

// use office "SureStore" or "TSSC" based on needs
const defaultConfig: ConfigState = {
  serverUrl: `${process.env.REACT_APP_SERVER_URL}`,
  office: "SureStore",
  fileAttachment: {
    enabled: true,
    maxFileSize: 16777216, // 16 MB
    acceptedExtensions: [
      "jpg",
      "jpeg",
      "png",
      "amr",
      "mp3",
      "mp4",
      "pdf",
      "txt",
      "ogg",
    ],
  },
};

const store = getConfiguredStore();
const dispatch = getDispatch();

const initWebchat = async (config: ConfigState) => {
  const mergedConfig = merge({}, defaultConfig, config);
  sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
  dispatch(loadConfig(mergedConfig));

  const loadConfigData = await configDataHandler.getConfigData(
    mergedConfig.office
  );
  dispatch(
    setThemes({
      webchatTheme: loadConfigData.theme,
    })
  );
  dispatch(setStoreOptions(loadConfigData.storeOptions));
  dispatch(
    setDefaultStoreOption(getDefaultStoreOption(loadConfigData.storeOptions))
  );
  dispatch(setFlexChatAddress(loadConfigData.flexChatAddress));

  dispatch(applyWebchatFeatureConfig(loadConfigData));

  initLogger();
  const rootElement = document.getElementById("twilio-webchat-widget-root");

  const { WebchatWidget } = await import("./components/WebchatWidget");

  render(
    <Provider store={store}>
      <WebchatWidget />
    </Provider>,
    rootElement
  );
};

declare global {
  interface Window {
    Twilio: {
      initWebchat: (config: ConfigState) => Promise<void>;
    };
  }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
  Twilio: {
    initWebchat,
  },
});
