export async function contactBackend<T>(
  endpoint: string,
  endpointRoute: string,
  method: "GET" | "POST",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _body: Record<string, unknown> = {}
): Promise<T> {
  const response = await fetch(endpoint + endpointRoute, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Request to backend failed");
  }
  const data = await response.json();
  return data;
}
