import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  Client,
  Conversation,
  Message,
  Participant,
  User,
} from "@twilio/conversations";
import { AppState } from "../definitions";

export type ChatState = {
  conversationsClient?: Client;
  conversation?: Conversation;
  participants?: Participant[];
  users?: User[];
  messages?: Message[];
  attachedFiles?: File[];
  conversationState?: "active" | "inactive" | "closed";
};

const initialState: ChatState = {};

export type ParticipantUserState = {
  participant: Participant;
  user: User;
};

function detachFilesAction(
  attachedFiles: File[] = [],
  filesToDetach: File[] = []
): File[] {
  return (attachedFiles || []).filter(
    (file: File) =>
      !filesToDetach.some(
        (fileToDetach: File) =>
          file.name === fileToDetach.name &&
          file.type === fileToDetach.type &&
          file.size === fileToDetach.size
      )
  );
}

export const chatSlice = createSlice({
  name: "webchat-chat",
  initialState,
  reducers: {
    startChatSession: (state, action: PayloadAction<ChatState>) => {
      state.conversationsClient = action.payload.conversationsClient;
      state.conversation = action.payload.conversation;
      state.conversationState = action.payload.conversationState;
      state.users = action.payload.users;
      state.participants = action.payload.participants;
      state.messages = action.payload.messages;
    },
    addMultipleMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = (state.messages || []).concat(action.payload);
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages = (state.messages || []).concat(action.payload);
    },
    removeMessage: (state, action: PayloadAction<Message>) => {
      state.messages = (state.messages || []).filter(
        (m) => m.sid !== action.payload.sid
      );
    },
    updateMessage: (state, action: PayloadAction<Message>) => {
      state.messages =
        state.messages?.reduce((acc, m) => {
          if (m.sid === action.payload.sid) {
            acc.push(action.payload);
          } else {
            acc.push(m as Message);
          }
          return acc;
        }, [] as Message[]) || [];
    },
    attachFiles: (state, action: PayloadAction<File[]>) => {
      state.attachedFiles = (state.attachedFiles || []).concat(action.payload);
    },
    detachFiles: (state, action: PayloadAction<File[]>) => {
      const filesToDetach = action.payload;
      state.attachedFiles = detachFilesAction(
        state.attachedFiles,
        filesToDetach
      );
    },
    addParticipant: (state, action: PayloadAction<ParticipantUserState>) => {
      state.participants = (state.participants || []).concat(
        action.payload.participant
      );
      state.users = (state.users || []).concat(action.payload.user);
    },
    removeParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = (state.participants || []).filter(
        (p) => p.sid !== action.payload.sid
      );

      state.users = (state.users || []).filter(
        (u) => u.identity !== action.payload.identity
      );
    },
    updateParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants =
        state.participants?.reduce((acc, p) => {
          if (p.sid === action.payload.sid) {
            acc.push(action.payload);
          } else {
            acc.push(p as Participant);
          }
          return acc;
        }, [] as Participant[]) || [];
    },
    updateConversationState: (
      state,
      action: PayloadAction<ChatState["conversationState"]>
    ) => {
      state.conversationState = action.payload;
    },
  },
});

export const {
  startChatSession,
  addMultipleMessages,
  addMessage,
  removeMessage,
  updateMessage,
  attachFiles,
  detachFiles,
  addParticipant,
  removeParticipant,
  updateParticipant,
  updateConversationState,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;

export const useSelectChatState = () =>
  useSelector((state: AppState) => ({
    conversationState: state.chat.conversationState,
    messages: state.chat.messages,
    participants: state.chat.participants,
    users: state.chat.users,
    conversation: state.chat.conversation,
    conversationsClient: state.chat.conversationsClient,
    attachedFiles: state.chat.attachedFiles || [],
    fileAttachmentConfig: state.config.fileAttachment,
  }));
