import { useSelector } from "react-redux";
import { AppState } from "../definitions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum EngagementPhase {
  PreEngagementForm = "PreEngagementForm",
  MessagingCanvas = "MessagingCanvas",
  Loading = "Loading",
  InitialOptionsPhase = "InitialOptionsPhase",
  CallPhase = "CallPhase",
  SMSPhase = "SMSPhase",
  WhatsAppPhase = "WhatsAppPhase",
}

export type PreEngagementData = {
  name: string;
  existingCustomer: string;
  store: string;
  email: string;
  phoneNumber: string;
};

export type SessionState = {
  currentPhase: EngagementPhase;
  expanded: boolean;
  token?: string;
  conversationSid?: string;
  preEngagementData: PreEngagementData;
};

const initialState: SessionState = {
  currentPhase: EngagementPhase.Loading,
  expanded: false,
  preEngagementData: {
    name: "",
    existingCustomer: "y",
    store: "",
    email: "",
    phoneNumber: "",
  },
};

export const sessionSlice = createSlice({
  name: "webchat-session",
  initialState,
  reducers: {
    startSession: (
      state,
      action: PayloadAction<
        Pick<SessionState, "token" | "conversationSid" | "currentPhase">
      >
    ) => {
      state.token = action.payload.token;
      state.conversationSid = action.payload.conversationSid;
      state.currentPhase = action.payload.currentPhase;
    },
    updateSessionData: (
      state,
      action: PayloadAction<Pick<SessionState, "token" | "conversationSid">>
    ) => {
      state.token = action.payload.token;
      state.conversationSid = action.payload.conversationSid;
    },
    changeExpandedStatus: (
      state,
      action: PayloadAction<Pick<SessionState, "expanded">>
    ) => {
      state.expanded = action.payload.expanded;
    },
    changeEngagementPhase: (
      state,
      action: PayloadAction<Pick<SessionState, "currentPhase">>
    ) => {
      state.currentPhase = action.payload.currentPhase;
    },
    updatePreEngagementData: (
      state,
      action: PayloadAction<{ preEngagementData: PreEngagementData }>
    ) => {
      state.preEngagementData = {
        ...state.preEngagementData,
        ...action.payload.preEngagementData,
      };
    },
    setDefaultStoreOption: (state, action: PayloadAction<string>) => {
      state.preEngagementData.store = action.payload;
    },
  },
});

export const {
  startSession,
  updateSessionData,
  changeExpandedStatus,
  changeEngagementPhase,
  updatePreEngagementData,
  setDefaultStoreOption,
} = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;

export const useSelectSessionState = () =>
  useSelector((state: AppState) => ({
    currentPhase: state.session.currentPhase,
    expanded: state.session.expanded,
    preEngagementData: state.session.preEngagementData,
  }));
