import { ThemeFromSyncDoc } from "../../utils/themeTransformer";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../definitions";

export type Theme = {
  default: string;
  light?: Partial<ThemeFromSyncDoc>;
  dark?: Partial<ThemeFromSyncDoc>;
};

export type ThemeState = {
  webchatTheme?: Theme;
};

const initialState: ThemeState = {
  webchatTheme: {
    default: "",
    light: {},
    dark: {},
  },
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemes: (state, action: PayloadAction<ThemeState>) => {
      state.webchatTheme = action.payload.webchatTheme;
    },
  },
});

export const { setThemes } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;

export const useSelectWebchatTheme = () =>
  useSelector((state: AppState) => state.theme.webchatTheme);
