import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../definitions";

export type StoreOption = {
  value: string;
  label: string;
  selected: boolean;
};

export type StoreState = {
  storeOptions: StoreOption[];
  flexChatAddress: string;
};

const initialState: StoreState = {
  storeOptions: [],
  flexChatAddress: "",
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStoreOptions: (state, action: PayloadAction<StoreOption[]>) => {
      state.storeOptions = action.payload;
    },
    setFlexChatAddress: (state, action: PayloadAction<string>) => {
      state.flexChatAddress = action.payload;
    },
  },
});

export const { setStoreOptions, setFlexChatAddress } = storeSlice.actions;
export const storeReducer = storeSlice.reducer;

export const useSelectStoreState = () =>
  useSelector((state: AppState) => ({
    storeOptions: state.store.storeOptions,
    flexChatAddress: state.store.flexChatAddress,
  }));
