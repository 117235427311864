import { useSelector } from "react-redux";
import { AppState } from "../definitions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileAttachmentConfig } from "../../definitions";

export type ConfigState = {
  serverUrl?: string;
  office?: string;
  fileAttachment?: FileAttachmentConfig;
};

const initialState: ConfigState = {};

export const configSlice = createSlice({
  name: "webchat-config",
  initialState,
  reducers: {
    loadConfig: (state, action: PayloadAction<ConfigState>) => {
      state.fileAttachment = action.payload.fileAttachment;
      state.serverUrl = action.payload.serverUrl;
      state.office = action.payload.office;
    },
  },
});

export const { loadConfig } = configSlice.actions;
export const configReducer = configSlice.reducer;

export const useSelectConfigState = () =>
  useSelector((state: AppState) => ({
    fileAttachmentConfig: state.config.fileAttachment,
    office: state.config.office,
  }));
