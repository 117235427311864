import { StoreOption } from "../store/features/storeSlice";

export const getDefaultStoreOption = (storeOption: StoreOption[]) => {
  const selectedOption = storeOption.find(
    (option: StoreOption) => option.selected === true
  );
  const defaultValue = selectedOption
    ? selectedOption.value
    : storeOption[0].value;
  return defaultValue;
};
